<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card v-if="group" :elevation="isEmbeddedInApp ? 0 : 2">
      <GroupHeader v-if="!isEmbeddedInApp" :group="group" title="Team Feed"/>
        <v-card-text>
          Share messages, photos, and updates with your team members. This feed is only accessible by members of this team.
        </v-card-text>
        <FeedList :feedItems="feedItems" compact @refresh="loadFeed" @likeItem="likeItem" :getItemCommentsFn="getItemComments" :postCommentOnItemFn="postCommentOnItem" />
        <div class="mx-3">
          <PostCommentField @submit="postComment"/>
          <div class="text-muted">Messages you post are visible to all current and future team members.</div>
        </div>

        <div v-if="!isEmbeddedInApp">
          <v-card-title class="subtitle">Events connected by this group</v-card-title>
          <v-card-text v-if="group && (!group.events || group.events == 0)">
            <p class="mb-0">This group hasn't connected with any events.</p>
          </v-card-text>      
          <EventGrid v-if="group" minimal :routeResolver="resolveEventGroupRoute" :items="group.events" class="mx-4 mb-4"></EventGrid>
        </div>
        <br/>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import VueMarkdown from '@/components/VueMarkdown.vue'
import GroupHeader from "@/components/GroupHeader.vue";
import EventGrid from "@/components/EventGrid.vue";
import FeedList from "@/components/FeedList.vue";
import PostCommentField from "@/components/PostCommentField.vue";

export default {
  name: "ProfileGroupsView",
  components: {
    FeedList,
    EventGrid,
    GroupHeader,
    VueMarkdown,
    PostCommentField,
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: { name: 'profile' } },
        { text: 'Groups', exact: true, to: { name: 'profileGroups' } },
        { text: 'View group', disabled: true },
      ],
      group: null,
      feedItems: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getGroup(id) {
      var response = await profileService.getGroup(id);
      this.group = response.data;
      await this.loadFeed();
    },
    async loadFeed() {
      this.feedItems = (await profileService.getFeedGroup(this.group.id)).data.data;
    },
    async likeItem(itemId) {
      let response = await profileService.likeFeedGroupItem(this.group.id, itemId);
      this.$helpers.toastResponse(this, response.data, 'Thanks! Item has been liked.');
      this.loadFeed();
      //return response.data;
    },
    async getItemComments(itemId) {
      let response = await profileService.getFeedGroupItemComments(this.group.id, itemId);
      return response.data.data;
    },
    async postCommentOnItem(itemId, message) {
      let response = await profileService.postFeedGroupItemComment(this.group.id, itemId, message);
      this.$helpers.toastResponse(this, response.data, 'Thanks! Your comment has been placed.');
      if (response.data.status == 'OK') {
        this.loadFeed();
      }
      return response.data;
    },
    async postComment(message) {
      let response = await profileService.postFeedGroupComment(this.group.id, message);
      if (response.data.status == 'OK') {
        await this.loadFeed();
      }
      else {
        this.$helpers.toastResponse(this, response.data, 'Posted successfully.');
      }
    },
    resolveEventGroupRoute(eventId) {
      return {name: 'profileGroupsEvent', params: {id: this.group.id, eventId: eventId}}
    },

    async getProfile() {
      if (this.user) {
        await this.getGroup(this.$route.params.id);
      }
    },
  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

